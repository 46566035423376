import React from "react";
import {PageProps} from "gatsby";
import {TeamMember} from "../../@types/teamMembers";
import Layout from "../../components/layout";
import useFetch from "starkeydigital/React/Hooks/useFetch";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import styled from "@emotion/styled";
import SimpleCarousel from "starkeydigital/React/Components/simpleTestimonialCarousel";
import {StaticImage} from "gatsby-plugin-image";
import Row from "starkeydigital/React/Components/row";
import Link from "../../components/link";
interface Data {
  data: TeamMember;
}

const Id: React.FC<PageProps> = (props) => {
  const id = props.params.id;
  const {data, loading, error} = useFetch<Data>(
    `https://cms.starkeydigital.com/items/atd_team_member/${id}?fields=*.*`,
  );

  const imageUrl =
    "https://cms.starkeydigital.com/assets/" + data?.data.image.id;

  if (loading) return <div></div>;

  if (error) {
    props.navigate("/404");
  }

  const slogan = data?.data.atd_team_member_qualification
    .map((q) => q.qualification)
    .join(", ");

  const firstName = data?.data.name.split(" ")[0] + "'s Biography";

  const Divider = styled.div`
    width: 100%;
    height: 1px;
    /* Neutrals/6 */
    background: #e6e8ec;
    margin: 50px 0;
  `;

  const Text = styled.div`
    font-family: Poppins;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.025em;

    color: #02578c;
  `;

  return (
    <Layout
      titleText={data?.data.name!}
      titleText2=""
      slogan={slogan!}
      includeRandomTestimonial={false}
      includeTestimonials={false}
      imageUrl={imageUrl}
    >
      <HeaderDivideTextLeft
        header={firstName}
        column="col"
        style={{marginTop: "115px"}}
      />

      <p
        dangerouslySetInnerHTML={{
          __html: data?.data.description.toString() ?? "",
        }}
        style={{marginTop: "35px", textAlign: "left"}}
      />

      <Divider />

      <SimpleCarousel
        leftArrow={<StaticImage src="../images/right.png" alt="right arrow" />}
        rightArrow={<StaticImage src="../images/left.png" alt="left arrow" />}
      >
        {data?.data.atd_team_member_endorsment.map((endorsment, index) => (
          <div style={{textAlign: "left"}}>
            <StaticImage src="../images/quote-top.png" alt="quote" />

            <Text
              key={index}
              dangerouslySetInnerHTML={{
                __html: endorsment.endorsment,
              }}
            />
            <div className="row justify-content-end">
              <StaticImage
                src="../images/quote-bottom.png"
                alt="quote"
                style={{marginRight: "15px"}}
              />
            </div>
          </div>
        ))}
      </SimpleCarousel>

      <Row justify="center">
        <Link className="button button-secondary" to="/who-we-are/our-team">
          Go Back
        </Link>
      </Row>
    </Layout>
  );
};
export default Id;
